import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterForm, FilterItem, useFilterUtils, useTableFilter } from '../../../shared/components';

const defaultValues = {
  id: {
    operator: 'equals',
    value: ''
  },
  name: {
    operator: 'contains',
    value: ''
  },
  shortName: {
    operator: 'contains',
    value: ''
  }
};

export const ManufacturerFilterForm = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('stockManager');

  filters = {
    ...defaultValues,
    ...filters
  };

  const handleOperatorChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], operator: value });
  };

  const handleValueChange = ({ target: { name, value } }) => {
    onFilterChange(name, { ...filters[name], value: value });
  };

  return (
    <FilterForm>
      {/* ID */}
      <FilterItem
        type="number"
        name="id"
        label={t('manufacturer.list.filter.fields.id')}
        filter={filters.id}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [3, 5, 0] }}
        valueContainerProps={{ xs: [0, 8, 3] }}
      />

      {/* Name */}
      <FilterItem
        type="string"
        name="name"
        label={t('manufacturer.list.filter.fields.name')}
        filter={filters.name}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [3, 5, 0] }}
        valueContainerProps={{ xs: [0, 8, 3] }}
      />

      {/* ShortName */}
      <FilterItem
        type="string"
        nullable
        name="shortName"
        label={t('manufacturer.list.filter.fields.short-name')}
        filter={filters.shortName}
        onOperatorChange={handleOperatorChange}
        onValueChange={handleValueChange}
        operatorContainerProps={{ xs: [3, 5, 0] }}
        valueContainerProps={{ xs: [0, 8, 3] }}
      />
    </FilterForm>
  );
};
