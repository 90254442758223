import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentTenant } from './useCurrentTenant';

// import MaintenanceModule from './modules/maintenance';
// import MqttModule from './modules/mqtt';
import StockManagerModule from '../../modules/stock-manager';
import WebsiteUserModule from '../../modules/website-user';
import { useMemo } from 'react';

const availableModules = {
  // 'maintenance': MaintenanceModule,
  // 'mqtt': MqttModule,
  'stockManager': StockManagerModule,
  'websiteUser': WebsiteUserModule
};

const ModuleContext = createContext({
  modules: {},
  currentModule: null,
  currentView: null
});

export const ModuleProvider = ({ children }) => {
  const location = useLocation();
  const { tenant } = useCurrentTenant();

  const [currentModule, setCurrentModule] = useState(null);
  const [currentView, setCurrentView] = useState(null);

  useEffect(() => {
    const parts = location.pathname.split('/');
    const [, module, view] = parts;

    setCurrentModule(module || null);
    setCurrentView(view || null);
  }, [location]);

  const modules = useMemo(() => {
    const modules = [];

    if (tenant) {
      for (const property in availableModules) {
        const module = availableModules[property];
  
        const hasPermission = tenant.modules.some(tenantModule => (
          tenantModule.abbreviation.toLowerCase() === module.abbreviation.toLowerCase()
        ));
  
        // WebsiteUserModule -> Core module
        if (hasPermission || module === WebsiteUserModule) {
          modules.push(module);
        }
      }
    }

    return modules;
  }, [tenant]);

  const value = {
    modules,
    currentModule,
    currentView
  };

  return (
    <ModuleContext.Provider value={value}>
      {children}
    </ModuleContext.Provider>
  );
};

export const useModules = () => {
  return useContext(ModuleContext);
};
