import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { GroupAdd as GroupAddIcon } from '@mui/icons-material';
import { useTable } from '../../../shared/components';
import { CustomerActionsMenu } from './CustomerActionsMenu';
import { CustomerFilterForm, customerFilterFormDefaultValues } from './CustomerFilterForm';
import { useTableV2 } from '../../../shared/components/table/hooks/useTableV2';
import { CustomerService } from './CustomerService';

const columnsFactory = (t) => [{
  header: t('stockManager:customer.list.columns.id'),
  field: 'id',
  width: '15%'
}, {
  header: t('stockManager:customer.list.columns.name'),
  field: 'name',
  width: '25%'
}, {
  header: t('stockManager:customer.list.columns.discount'),
  field: 'discountPercentage',
  render: (row) => `${row.discountPercentage}%`,
  width: '15%',
  align: 'right'
}, {
  header: t('stockManager:customer.list.columns.points'),
  field: 'collectedPoints',
  width: '15%',
  align: 'right'
}, {
  header: t('stockManager:customer.list.columns.shop'),
  field: 'shop',
  render: (row) => row.shop?.name || '-',
  width: '20%',
  align: 'right'
}, {
  width: '10%',
  align: 'right',
  sortable: false,
  render: (row, { refresh }) => (
    <CustomerActionsMenu
      customer={row}
      onDelete={refresh}
    />
  )
}];

export const useCustomerList = () => {
  const { t } = useTranslation('stockManager');
  const columns = useMemo(() => columnsFactory(t), [t]);

  const tableProps = useTableV2({
    columns,
    title: t('customer.list.title'),
    icon: <GroupAddIcon />,
    dataSource: CustomerService.getCustomersPaginated,
    initialState:{
      sorting: {
        column: 'name',
        direction: 'asc'
      }
    },
    components: {
      toolbar: {
        createButtonProps: {
          to: '/stock-manager/customer/editor',
          children: t('customer.list.actions.create.label')
        },
        filterModalProps: {
          maxWidth: 'md',
          title: t('customer.list.filter.title'),
          children: CustomerFilterForm
        }
      }
    }
  });

  return {
    tableProps
  };
};
