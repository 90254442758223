import { saleActions } from '../saleActions';
import { useSaleContext } from '../context/SaleContext';

export const useQuantitySelector = () => {
  const { quantity, product, dispatch } = useSaleContext();

  const handleQuantityChanged = (newValue) => {
    if (!product?.id) {
      return;
    }

    if ((newValue === 0 || newValue === ',') && quantity.raw.length === 0) {
      return;
    }

    const values = [...quantity.raw];

    if (newValue === 'delete') {
      if (values.length > 0){
        values.splice(values.length - 1, 1)
      }
    }
    else if (newValue === ',') {
      if (!values.includes('.') && values[values.length - 1] !== '.') {
        values.push('.');
      }
    }
    else {
      values.push(newValue);
    }

    let rawNumber = '';

    values.forEach(value => {
      rawNumber += value.toString();
    });

    if (rawNumber.endsWith('.')) {
      rawNumber += '0';
    }

    dispatch(saleActions.setQuantity({
      raw: values,
      parsed: parseFloat(rawNumber || '0')
    }));
  };

  const addToBasket = () => {
    dispatch(saleActions.addToBasket());
  }

  const hasProduct = () => {
    return !!product;
  };

  const stockExcedeed = () => {
    return quantity.parsed > product.currentAmount;
  };

  const totalPrice = () => {
    return (quantity.parsed * product.price).toFixed(2);
  };

  return {
    product,
    hasProduct,
    quantity: quantity.parsed,
    handleQuantityChanged,
    totalPrice,
    stockExcedeed,
    addToBasket
  };
};
