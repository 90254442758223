import i18n from 'i18next';
import { DateTime } from 'luxon';
import { priceFormatter } from './PriceFormatter';

const dateFormatter = (date, format) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString(format);
};

const yearAndMonthFormatter = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return DateTime
    .fromISO(date)
    .setLocale(i18n.language)
    .toLocaleString({ year: 'numeric', month: 'long' });
};

export const formatters = {
  price: priceFormatter,
  date: dateFormatter,
  yearAndMonth: yearAndMonthFormatter
};
