import i18next from 'i18next';

export const Roles = {
  'ACM': [
    { id: 'acm-admin', name: i18next.t('maintenance:roles.admin') },
    { id: 'acm-user', name: i18next.t('maintenance:roles.user') }
  ],
  'STM': [
    { id: 'stm-admin', name: i18next.t('stockManager:roles.stm-admin') },
    { id: 'stm-shop-manager', name: i18next.t('stockManager:roles.stm-shop-manager') },
    { id: 'stm-shopkeeper', name: i18next.t('stockManager:roles.stm-shopkeeper') }
  ]
};
