import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { IconButton, TextField } from '@mui/material';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// const Buttons = ({ onChange }) => {
//   const handleIncrement = () => {
//     onChange('increment');
//   };

//   const handleDecrement = () => {
//     onChange('decrement');
//   };

//   return (
//     <>
//       <IconButton size="small" onClick={handleIncrement}>
//         <ArrowUpwardIcon fontSize="small" />
//       </IconButton>
//       <IconButton size="small" onClick={handleDecrement}>
//         <ArrowDownwardIcon fontSize="small" />
//       </IconButton>
//     </>
//   );
// };

export const EmpNumberField = ({ controllerProps, numberFieldProps }) => {
  if (controllerProps) {
    return <EmpNumberFieldForm controllerProps={controllerProps} numberFieldProps={numberFieldProps} />
  }
  else {
    return <EmpNumberFieldRegular numberFieldProps={numberFieldProps} />
  }
};

const EmpNumberFieldRegular = ({ numberFieldProps }) => {
  return (
    <TextField
      {...numberFieldProps}
      variant="standard"
      InputProps={{
        ...numberFieldProps?.InputProps,
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};

const EmpNumberFieldForm = ({ controllerProps, numberFieldProps }) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <TextField
          {...numberFieldProps}
          variant="standard"
          InputProps={{
            ...field,
            ...numberFieldProps?.InputProps,
            inputComponent: NumberFormatCustom,
          }}
        />
      )}
    />
  );
};

const NumberFormatCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value || undefined,
          }
        });
      }}
    />
  );
});
