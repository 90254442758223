import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

const styles = {
  root: {
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    paddingBottom: '2.5rem',
  },
  icon: {
    fontSize: 180,
    color: 'red'
  },
  text: {
    fontSize: '4rem'
  }
};

export const NotAuthorizedPage = () => {
  const { t } = useTranslation('core');

  return (
    <Box sx={styles.root}>
      <ErrorIcon sx={styles.icon} />
      <Box sx={styles.textWrapper}>
        <Typography sx={styles.text}>
          {/* {t('general.not-found.title')} */}
          401
        </Typography>
        <Typography>
          {/* {t('general.not-found.content-1')} */}
          We can't let you see this page
        </Typography>
        <Typography>
          {/* {t('general.not-found.content-2')} */}
          You don't have the necessary permissions
        </Typography>
      </Box>
    </Box>
  );
};
