import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Dashboard as DashboardIcon } from '@mui/icons-material';

import { MenuItem } from './components/MenuItem';
import { MenuCollapse } from './components/MenuCollapse';
import { MenuExpander } from './components/MenuExpander';

import { UserProfileMenuItem } from './menu-items/UserProfileMenuItem';
import { LanguageSelectorMenuItem } from './menu-items/LanguageSelectorMenuItem';
import { LogoutMenuItem } from './menu-items/LogoutMenuItem';

import { Routes } from '../../Routes';
import { EmpStyles } from '../../shared/styles/EmpStyles';
import { useModules } from '../../shared/hooks/useModules';

const styles = {
  list: {
    minWidth: {
      xs: '65vw',
      sm: '25vw'
    },
    overflowX: 'hidden',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...EmpStyles.scrollbar
  }
};

export const SideMenu = () => {
  const { t } = useTranslation('core');
  const { modules, currentModule } = useModules();

  return (
    <Box sx={styles.list}>
      <Box>
        <List>
          <Link to="/dashboard" component={RouterLink}>
            <ListItem button sx={!currentModule || currentModule === 'dashboard' ? EmpStyles.menu.active : {}}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={t('side-menu.dashboard')} />
            </ListItem>
          </Link>
          {modules.map(module => (
            <ModuleMenu key={module.abbreviation} module={module} />
          ))}
        </List>
      </Box>
      <Box>
        <UserProfileMenuItem />
        <LanguageSelectorMenuItem />
        <LogoutMenuItem />
      </Box>
    </Box>
  );
};

const ModuleMenu = ({ module }) => {
  const menu = module.menu();
  const { active, name, icon, label, visible, children } = menu;

  const [open, setOpen] = useState(active);

  if (!visible) {
    return <></>;
  }

  return (
    <MenuItem key={name} module={menu}>
      <ListItem
        button
        sx={active ? EmpStyles.menu.active : {}}
        onClick={() => setOpen(!open)}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
        <MenuExpander open={open} subMenus={children} />
      </ListItem>
      <MenuCollapse open={open}>
        {children}
      </MenuCollapse>
    </MenuItem>
  );
};
