import { useTranslation } from 'react-i18next';

import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography
} from '@mui/material';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Remove as RemoveIcon,
  ShoppingBasket as ShoppingBasketIcon
} from '@mui/icons-material'

import { TransitionGroup } from 'react-transition-group';

import { priceFormatter } from '../../utils/PriceFormatter';
import { useBasket } from '../hooks/useBasket';
import { SalePanel } from './SalePanel';

const styles = {
  scrollable: {
    height: '100%'
  },
  gridRoot: {
    p: 1,
    height: '100%'
  },
  emptyRoot: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  item: {
    backgroundColor: 'primary.light',
    borderRadius: '4px',
    boxShadow: (theme) => theme.shadows[2],
    margin: (theme) => theme.spacing(0, 0, 1, 0),
    padding: (theme) => theme.spacing(0, 0, 0, 0.75)
  },
  quantityRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const BasketPanel = ({ viewer }) => {
  const { t } = useTranslation('stockManager');

  return (
    <SalePanel
      title={t('sale.basket-panel.title')}
      icon={<ShoppingBasketIcon />}
    >
      <Basket viewer={viewer} />
    </SalePanel>
  );
};

export const Basket = ({ viewer }) => {
  const { t } = useTranslation('stockManager');

  const {
    basket,
    discount,
    remove,
    incrementQuantity,
    decrementQuantity
  } = useBasket();

  if (basket.products.length === 0) {
    return (
      <Box sx={styles.emptyRoot}>
        <Typography variant="h5">
          {t('sale.basket-panel.empty')}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container sx={styles.gridRoot}>
      <Grid item xs={12} sx={styles.scrollable}>
        <TransitionGroup>
          {basket.products.map(product => (
            <Collapse key={product.id}>
              <Grid container alignItems="center" sx={styles.item}>
                <Grid item xs={4}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {product.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={styles.quantityRoot}>
                    <IconButton color="primary" onClick={() => incrementQuantity(product)}>
                      <AddIcon />
                    </IconButton>
                    <Typography sx={{ mx: 1.5 }}>
                      {product.quantity}x
                    </Typography>
                    <IconButton color="primary" onClick={() => decrementQuantity(product)}>
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  {!viewer && (
                    <Typography align="center">
                      {discount()}%
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography align="center">
                    {`${priceFormatter(product.customerDiscountedPrice)} ${product.currency.shortName}`}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {!viewer && (
                    <IconButton color="error" onClick={() => remove(product)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Collapse>
          ))}
        </TransitionGroup>
      </Grid>
    </Grid>
  );
};
