import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useEmpAuth } from '../AuthProvider';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const styles = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    mt: 2,
    borderRadius: '20px'
  }
};

export const LoginScreen = () => {
  const { t } = useTranslation('core');
  const { signin } = useEmpAuth();

  return (
    <Box sx={styles.root}>
      <Typography variant="h4">
        {t('login.title')}
      </Typography>

      <Button
        size="large"
        color="info"
        sx={styles.button}
        startIcon={<LockOpenIcon/>}
        onClick={() => signin()}
      >
        {t('login.button')}
      </Button>
    </Box>
  );
};
