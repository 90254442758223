import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import {
  Close as CloseIcon
} from '@mui/icons-material';

import {
  EmpLoadingButton,
  EmpLoadingIndicator
} from '.';

const styles = {
  loadingContainer: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  }
};

export const EmpModal = (props) => {
  // const { t } = useTranslation('core');

  const {
    open,
    onClose,
    loading = false,
    contentClassName,
    titleIcon,
    children,
    maxWidth,
    fullWidth,
    localization = {},
    // closeButtonProps = {},
    // okButtonProps = {},
    actions = ['close']
  } = props;

  const {
    title = '',
    // closeButtonText = t('general.cancel'),
    // okButtonText = t('general.ok')
  } = localization;

  // const {
  //   onClick: onClose = null
  // } = closeButtonProps;

  // const {
  //   loading: okLoading = false,
  //   onClick: onOk = null
  // } = okButtonProps;

  const handleClose = () => {
    onClose && onClose();
  };

  // const handleOk = () => {
  //   onOk && onOk();
  // };

  // TODO - maybe support this approach
  // const renderActions = (action) => {
    // if (action.render) {
    //   return action.render();
    // }

    // if (action.name === 'close') {
    //   return (
    //     <Button variant="contained" color="error" onClick={handleClose}>
    //       {closeButtonText}
    //     </Button>
    //   );
    // }

    // if (action.name === 'ok') {
    //   return (
    //     <EmpLoadingButton variant="contained" color="primary" loading={okLoading} onClick={handleOk}>
    //       {okButtonText}
    //     </EmpLoadingButton>
    //   );
    // }
  // };

  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth} scroll="paper">
      <DialogTitle>
        <Box sx={styles.titleRoot}>
          <Box sx={styles.title}>
            {titleIcon}
            <Typography variant="h5" sx={{ ml: 2 }}>
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={contentClassName}>
          {loading ? (
            <div sx={styles.loadingContainer}>
              <EmpLoadingIndicator />
            </div>
          ) : (
            children
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {actions}
      </DialogActions>
    </Dialog>
  );
}
