import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid,  IconButton,  MenuItem, Select, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewListIcon from '@mui/icons-material/ViewList';
import { EmpLoadingIndicator, EmpModal } from '../../../../shared/components';
import { useCurrentUser } from '../../../../shared/hooks/useCurrentUser';
import { EmpDropdownSpinner } from '../../../../shared/components/EmpDropdown';
import { useSaleContext } from '../context/SaleContext';
import { saleActions } from '../saleActions';
import { SalePanel } from './SalePanel';

const styles = {
  emptyRoot: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inStockItem: {
    backgroundColor: 'primary.light',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: (theme) => theme.spacing(0.75, 0.75),
    boxShadow: (theme) => theme.shadows[2],
    '&:hover': {
      color: 'sale.textHover',
      backgroundColor: 'primary.main'
    }
  },
  outOfStockItem: {
    backgroundColor: 'sale.disabled',
    borderRadius: '4px',
    padding: (theme) => theme.spacing(0.75, 0.75),
    boxShadow: (theme) => theme.shadows[2],
  }
};

export const ProductSelectorPanel = () => {
  const { t } = useTranslation('stockManager');

  return (
    <SalePanel
      title={t('sale.product-panel.title')}
      icon={<ViewListIcon />}
      toolbar={<ProductSelectorPanelHeader />}
    >
      <ProductSelector />
    </SalePanel>
  );
};

const ProductSelectorPanelHeader = () => {
  const { t } = useTranslation('stockManager');

  const {
    dispatch,
    shop,
    shops,
    shopsLoading,
    showOutOfStockProducts
  } = useSaleContext();

  const [open, setOpen] = useState(false);

  const { hasAnyRole } = useCurrentUser();
  const isAdmin = hasAnyRole('stm-admin');

  let dropdownProps = {};

  if (shopsLoading) {
    dropdownProps.disabled = true;
    dropdownProps.IconComponent = EmpDropdownSpinner;
  }

  return (
    <>
      <IconButton size="small" onClick={() => setOpen(true)} sx={{ color: 'black' }}>
        <SettingsIcon />
      </IconButton>
      <EmpModal
        open={open}
        onClose={() => setOpen(false)}
        titleIcon={<SettingsIcon />}
        maxWidth="xs"
        fullWidth
        actions={
          <Button color="error" onClick={() => setOpen(false)}>
            {t('sale.product-panel.advanced-settings-modal.close')}
          </Button>
        }
        localization={{
          title: t('sale.product-panel.advanced-settings-modal.title')
        }}
      >
        {isAdmin && (
          <Box sx={{ width: '60%' }}>
            <FormControl sx={{ mb: 3 }}>
              <Select
                value={shop}
                onChange={({ target }) => dispatch(saleActions.setShop(target.value))}
                {...dropdownProps}
              >
                {shops.map((shop, index) => (
                  <MenuItem key={index} value={shop.id}>
                    {shop.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <FormControlLabel
          label={t('sale.product-panel.advanced-settings-modal.show-out-of-stock-products')}
          control={
            <Checkbox
              value={showOutOfStockProducts}
              onChange={({ target }) => {
                dispatch(saleActions.setShowOutOfStockProducts(target.checked));
              }}
            />
          }
        />
      </EmpModal>
    </>
  );
};

const ProductSelector = () => {
  const { t } = useTranslation('stockManager');

  const {
    dispatch,
    shopsLoading,
    products,
    productCategory,
    productCategories,
    productCategoriesLoading,
    productCategoriesError
  } = useSaleContext();

  if (shopsLoading || productCategoriesLoading) {
    return (
      <Box sx={styles.emptyRoot}>
        <EmpLoadingIndicator />
      </Box>
    );
  }

  if (productCategoriesError) {
    let errorMessage = t('core:general.api.unkown-error');

    if (productCategoriesError.errors?.length > 0) {
      errorMessage = productCategoriesError.errors[0].message;
    }

    return (
      <Box sx={styles.emptyRoot}>
        <Typography variant="h5">
          {errorMessage}
        </Typography>
      </Box>
    );
  }

  if (!productCategories?.length) {
    return (
      <Box sx={styles.emptyRoot}>
        <Typography variant="h5">
          {t('sale.product-panel.empty')}
        </Typography>
      </Box>
    );
  }

  if (productCategory) {
    return (
      <Box p={1}>
        <Grid container columns={18} spacing={1}>
          <Grid item xs={9} sx={{ display: 'flex' }}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  dispatch(saleActions.resetProductCategory());
                }}
              >
                {t('sale.product-panel.back')}
              </Button>
            </Box>
          </Grid>
          {products.map((product, index) => (
            <Grid item key={index} xs={index === 0 ? 9 : 9} sx={{ display: 'flex' }}>
              <ProductItem product={product} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box p={1}>
      <Grid container spacing={1}>
        {productCategories.map((productCategory, index) => (
          <Grid item key={index} xs={3} sx={{ display:'flex' }}>
            <ProductCategoryItem productCategory={productCategory} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const ProductCategoryItem = ({ productCategory }) => {
  const { t } = useTranslation('stockManager');
  const { dispatch } = useSaleContext();

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={styles.inStockItem}
      onClick={() => dispatch(saleActions.setProductCategory(productCategory))}
    >
      <Typography align="center" sx={{ fontWeight: 'bold' }}>
        {productCategory.name}
      </Typography>
      <Typography align="center" variant="body2">
        {productCategory.products.length} {t('sale.product-panel.products')}
      </Typography>
    </Box>
  );
};

const ProductItem = ({ product }) => {
  const { t } = useTranslation('stockManager');
  const { dispatch } = useSaleContext();

  const outOfStock = product.currentAmount <= 0;

  let currentAmount = `${product.currentAmount}`;
  let productUnit = `${product.productUnit.name}`;

  if (outOfStock) {
    currentAmount = t('sale.product-panel.out-of-stock');
    productUnit = '';

    if (product.inBasket) {
      currentAmount = t('sale.product-panel.in-basket');
    }
  }

  const handleClick = () => {
    if (!outOfStock) {
      dispatch(saleActions.setProduct(product));
    }
  };

  return (
    <Box
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={outOfStock ? styles.outOfStockItem : styles.inStockItem}
      onClick={handleClick}
    >
      <Typography sx={{ fontWeight: 'bold' }}>
        {product.name}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Typography variant="caption" align="center">
          {currentAmount}
        </Typography>
        <Typography variant="caption" align="center">
          {productUnit}
        </Typography>
      </Box>
    </Box>
  );
};
