export { NoLayout } from './layouts/NoLayout';
export { SideMenuLayout } from './layouts/SideMenuLayout';

export { DefaultRoute } from './routes/DefaultRoute';
export { PrivateRoute } from './routes/PrivateRoute';
export { PublicRoute } from './routes/PublicRoute';

export { ContactUsPage } from './pages/ContactUsPage';
export { NotFoundPage } from './pages/NotFoundPage';
export { UserProfilePage } from './pages/UserProfilePage';

export const Roles = Object.freeze({
  EMP_Admin: 'emp-admin'
});
