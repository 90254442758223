import { useTranslation } from 'react-i18next';

import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import { priceFormatter } from '../../utils/PriceFormatter';
import { useQuantitySelector } from '../hooks/useQuantitySelector';
import { useCurrentBreakpoint } from '../../../../shared/contexts/BreakpointProvider';

export const QuantitySelectorPanel = () => {
  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid
        item
        xs={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <QuantitySelector />
      </Grid>
      <Grid
        item
        xs={6}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <QuantitySummary />
      </Grid>
    </Grid>
  );
};

const QuantitySelector = () => {
  return (
    <Box>
      <NumberButtonRow numbers={[1, 2, 3]} />
      <NumberButtonRow numbers={[4, 5, 6]} />
      <NumberButtonRow numbers={[7, 8, 9]} />
      <NumberButtonRow numbers={[0, 10, 12]} />
      <NumberButtonRow numbers={[',', 'delete']} />
    </Box>
  );
};

const NumberButtonRow = ({ numbers }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {numbers.map((number, index) => (
        <NumberButton key={index} value={number} />
      ))}
    </Box>
  );
};

const NumberButton = ({ value }) => {
  const { t } = useTranslation('stockManager');
  const { handleQuantityChanged } = useQuantitySelector();

  return (
    <Box
      sx={numberButtonStyler(value)}
      onClick={() => handleQuantityChanged(value)}
    >
      {t(`sale.quantity-panel.actions.${value}`, value.toString())}
    </Box>
  );
};

const QuantitySummary = () => {
  const { t } = useTranslation('stockManager');
  const { currentBreakpoint } = useCurrentBreakpoint();

  const {
    product,
    hasProduct,
    quantity,
    totalPrice,
    stockExcedeed,
    addToBasket
  } = useQuantitySelector();

  if (!hasProduct()) {
    return (
      <Typography variant="h5" align="center">
        {t('sale.quantity-panel.empty')}
      </Typography>
    );
  }

  if (currentBreakpoint === 'xs' || currentBreakpoint === 'sm' || currentBreakpoint === 'md') {
    return (
      <>
        <Box>
          <Typography align="center" sx={{ fontWeight: 'bold' }}>
            {product.name}
          </Typography>
          <Typography variant="body2" align="center">
            {product.currentAmount <= 0 ? t('sale.quantity-panel.out-of-stock') : `${product.currentAmount} ${product.productUnit.name}`}
          </Typography>
        </Box>

        <Divider variant="middle" sx={{ my: 1.5 }} />

        <Box>
          <Typography align="center">
            {`${quantity} x ${`${priceFormatter(product.price)} ${product.currency.shortName}`}`}
          </Typography>
        </Box>

        <Divider variant="middle" sx={{ my: 1.5 }} />

        <Box>
          <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
            {`${priceFormatter(totalPrice())} ${product.currency.shortName}`}
          </Typography>
        </Box>

        <Box
          mt={4}
          display="flex"
          justifyContent="center"
        >
          <Button
            startIcon={<ShoppingBasketIcon />}
            disabled={quantity <= 0 || stockExcedeed()}
            onClick={addToBasket}
          >
          {t('sale.quantity-panel.actions.add')}
        </Button>
      </Box>
      </>
    );
  }

  return (
    <>
      <QuantitySummaryRow
        label={t('sale.quantity-panel.fields.product')}
        content={product.name}
      />

      <QuantitySummaryRow
        label={t('sale.quantity-panel.fields.stock')}
        content={<>{product.currentAmount <= 0 ? t('sale.quantity-panel.out-of-stock') : `${product.currentAmount} ${product.productUnit.name}`}</>}
      />

      <QuantitySummaryRow
        label={t('sale.quantity-panel.fields.unit-price')}
        content={<>{priceFormatter(product.price)} {product.currency.shortName}</>}
      />

      <QuantitySummaryRow
        label={t('sale.quantity-panel.fields.quantity')}
        content={(
          <Typography sx={{ color: stockExcedeed() ? 'red' : 'black', fontWeight: 'bold' }}>
            {quantity} {product.productUnit.name}
          </Typography>
        )}
      />

      <QuantitySummaryRow
        label={t('sale.quantity-panel.fields.payable')}
        content={<>{priceFormatter(totalPrice())} {product.currency.shortName}</>}
      />

      <Box
        mt={4}
        display="flex"
        justifyContent="center"
      >
        <Button
          startIcon={<ShoppingBasketIcon />}
          disabled={quantity <= 0 || stockExcedeed()}
          onClick={addToBasket}
        >
          {t('sale.quantity-panel.actions.add')}
        </Button>
      </Box>
    </>
  );
};

const QuantitySummaryRow = ({ label, content }) => {
  return (
    <Box
      mt={1}
      display="flex"
      justifyContent="space-between"
    >
      <Typography>
        {label}
      </Typography>
      <Typography>
        <b>{content}</b>
      </Typography>
    </Box>
  );
};

const numberButtonStyler = (number) => ({
  height: '50px',
  width: number === 'delete' ? (theme) => `calc(100px + ${theme.spacing(1)})` : '50px',
  color: number === 'delete' ? 'white' : 'black',
  backgroundColor: number === 'delete' ? 'error.main' : 'primary.light',
  margin: (theme) => theme.spacing(0, 1, 1, 0),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  cursor: 'pointer',
  borderRadius: '4px',
  boxShadow: (theme) => theme.shadows[2],
  '&:hover': {
    color: number === 'delete' ? 'white' : 'white',
    backgroundColor: number === 'delete' ? 'error.dark' : 'primary.main'
  }
});
